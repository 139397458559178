.tooltip {
  padding: 15px;
  border-radius: 4px;
  box-shadow: 0px 5px 10px rgba(82, 63, 105, 0.15);
  p {
    font-size: 11px;
    line-height: 16px;
    color: rgba(72, 70, 91, 1);
  }
}
