.textarea-wrapper {
  width: 100%;
  .textarea-group {
    textarea {
      width: 100%;
      padding: 9px;
      border-radius: 4px;
      border: none;
      border: 1px solid $input-form-border;
      width: 100%;
      outline: none;
      transition: 0.2s;
      font-size: 13px;
      line-height: 19px;
      &:focus,
      &.visited {
        border-color: #9aabff;
      }
    }
    display: flex;
  }
}
