.check-group {
  margin-top: 22px;
  padding-left: 25px;

  input {
    display: none;
    &:checked + label::after {
      opacity: 1;
    }
  }
  label {
    cursor: pointer;
    color: #595d6e;
    position: relative;
    &::before {
      content: '';
      width: 16px;
      height: 16px;
      position: absolute;
      top: 2px;
      left: -22px;
      background-color: #fff;
      border: 1px solid #d1d7e2;
      border-radius: 3px;
    }
    &::after {
      content: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPHN2ZyB2ZXJzaW9uPSIxLjEiDQoJaWQ9IkxheWVyXzEiDQoJeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIg0KCXhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIg0KCXg9IjBweCINCgl5PSIwcHgiDQoJdmlld0JveD0iMCAwIDUxMS45OTkgNTExLjk5OSINCgl4bWw6c3BhY2U9InByZXNlcnZlIj4NCgk8cGF0aCBkPSJNNTA2LjIzMSw3NS41MDhjLTcuNjg5LTcuNjktMjAuMTU4LTcuNjktMjcuODQ5LDBsLTMxOS4yMSwzMTkuMjExTDMzLjYxNywyNjkuMTYzYy03LjY4OS03LjY5MS0yMC4xNTgtNy42OTEtMjcuODQ5LDANCgkJCWMtNy42OSw3LjY5LTcuNjksMjAuMTU4LDAsMjcuODQ5bDEzOS40ODEsMTM5LjQ4MWM3LjY4Nyw3LjY4NywyMC4xNiw3LjY4OSwyNy44NDksMGwzMzMuMTMzLTMzMy4xMzYNCgkJCUM1MTMuOTIxLDk1LjY2Niw1MTMuOTIxLDgzLjE5OCw1MDYuMjMxLDc1LjUwOHoiIC8+DQo8L3N2Zz4=');
      position: absolute;
      top: 1px;
      left: -19px;
      width: 10px;
      height: 10px;
      opacity: 0;
      transition: 0.2s;
    }
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  .additional-info {
    font-size: 13px;
    line-height: 20px;
    color: #595d6e;
  }
}
.check-label-aside {
  display: flex;
  align-items: baseline;
  &__text {
    min-width: 70px;
    font-size: 13px;
    line-height: 20px;
    color: #646c9a;
  }
}

.check-main-label {
  color: #646c9a;
  & + .check-group {
    margin-top: 16px;
  }
}
