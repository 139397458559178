.comment {
  display: flex;
  align-items: flex-start;
  padding-right: 20px;
  &__image {
    flex-basis: 60px;
    max-width: 60px;
    min-width: 60px;
    padding-right: 20px;
    svg {
      max-width: 100%;
    }
  }
  &__text {
    .user {
      font-size: 13px;
      line-height: 20px;
      color: rgba(24, 28, 50, 0.9);
      font-weight: 500;
    }
    .content {
      font-size: 13px;
      line-height: 20px;
      font-weight: 300;
    }
  }
}
