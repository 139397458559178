.tree {
  color: $table-head;
  padding: 0 20px;
  &__line {
    position: absolute;
    z-index: 2;
    &--vertical {
      top: 0px;
      bottom: 5px;
      left: 0;
      border-left: 1px dashed rgba(89, 125, 255, 0.4);
      transform: translate(2px, -30px);
    }
    &--horizontal {
      top: 50%;
      left: 23px;
      width: 16px;
      transform: translateY(-50%);
      border-bottom: 1px dashed rgba(89, 125, 255, 0.4);
    }
  }
  &__item {
    width: 100%;
    & > .table {
      width: calc(100% + 40px);
      margin-left: -20px;
      border-bottom: 1px solid $separator;
      td {
        padding: 20px 20px 20px 0;
      }
      .button__tree {
        margin-right: 5px;
      }
      .table__body {
        tr {
          td {
            width: 20%;
            &:first-child {
              min-width: 400px;
            }
          }
        }
      }
    }
    & > .tree__list {
      position: relative;
      & > .tree__item {
        & > .table {
          position: relative;
          margin-left: -20px;
          .table__body {
            tr {
              td:first-child {
                padding-left: 50px;
              }
            }
          }
        }
      }
    }
  }
}
