@mixin plus-rectangle {
  content: '';
  position: absolute;
  width: 1px;
  height: 7px;
  background-color: #5d78ff;
  z-index: 5;
}

@mixin button-reset {
  padding: 0;
  outline: none;
  background: none;
  border: none;
  cursor: pointer;
}