button {
  @include button-reset;
}

.btn {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  font-size: 13px;
  line-height: 20px;
  border-radius: 3px;
  padding: 5px 15px;
  border: 1px solid #e2e5ec;
  color: #595d6e;
  transition: 0.2s;
  &:disabled {
    pointer-events: none;
    background-color: #9c9c9c;
    opacity: 0.7;
  }
  svg {
    margin-right: 5px;
  }
  &:hover {
    border-color: #595d6e;
  }
  &:active {
    transform: scale(0.93);
  }
  &-lg {
    background-color: #9c9c9c;
    padding: 8px 18px;
    border-radius: 3px;
    font-size: 13px;
    font-family: 'Poppins', sans-serif;
    line-height: 20px;
    color: #fff;
  }
  &-sm {
    background-color: #9c9c9c;
    padding: 6px 18px;
    border-radius: 3px;
    font-size: 13px;
    font-family: 'Poppins', sans-serif;
    line-height: 20px;
    color: #fff;
    box-shadow: 0px 3px 16px #5a78ff26;
  }
  &__tree {
    margin-right: 4px;
    margin-left: -13px;
  }
  &-plus {
    position: relative;
    padding-left: 35px;
    &:before {
      content: '';
      position: absolute;
      width: 12px;
      height: 1px;
      top: 48%;
      left: 15px;
      background-color: #fff;
    }
    &:after {
      content: '';
      top: 48%;
      left: 15px;
      background-color: white;
      position: absolute;
      transform: rotate(90deg);
      width: 12px;
      height: 1px;
    }
  }
  &-blue {
    background-color: $blue;
  }
  &-pink {
    background-color: $pink;
  }
  &-green {
    background-color: $green;
  }
  &-cancel {
    background-color: white;
    border: 1px solid #e2e5ec;
    color: #595d6e;
    min-width: 100px;
    justify-content: center;
  }
  &-icon {
    border: none;
    padding: 5px;
    svg {
      margin: 0;
    }
  }
}
