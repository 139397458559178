.label {
    display: inline-block;
    font-size: 11px;
    line-height: 12px;
    letter-spacing: 0.81px;
    border-radius: 2px;
    padding: 4px 8px;
    background: transparent;
    color: #fff;
    font-weight: 300;
    &-sm {
      padding: 2px 4px;
      font-size: 10px;
    }
    &-bold {
      font-weight: 600;
    }
    &-round {
      border-radius: 4px;
    }
    &-up {
      text-transform: uppercase;
    }
    &--danger{
      background-color: #FD397A;
    }
    &--warning {
      background-color: #ffb822;
    }
    &--info {
      background-color: #5D78FF;
    }
     &--info-light {
      background-color: #44B2F1;
    }
    &--success {
      background-color: #0ABB87;
    }
    &--default {
      background-color: #93A2DD;
    }
    &--purple {
      background-color: #AE22FF;
    }
  }
