.login {
  &-container {
    position: relative;
    min-height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #1a1a27;
    .nav-button {
      position: absolute;
      top: 20px;
      left: 20px;
    }
    &::before {
      content: "";
      position: absolute;
      z-index: 1;
      bottom: 0;
      left: 0;
      right: 0;
      top: 35%;
      clip-path: polygon(0 50%, 100% 0, 100% 100%, 0% 100%);
      background: linear-gradient(180deg, $app-light 0%, $app-dark 100%);
    }
  }
  &-wrapper {
    max-width: 425px;
    margin: 40px;
    width: 100%;
  }
  &-header {
    display: flex;
    justify-content: center;
    padding-bottom: 50px;
  }
  &-panel {
    position: relative;
    z-index: 2;
    padding: 30px;
    background-color: #fff;
    border-radius: 5px;
  }
  &-submit {
    text-align: right;
    padding-top: 10px;
  }
}
