.pagination {
    padding: 20px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &-left {
      display: flex;
      align-items: center;
    }
    &-box {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #93a2dd;
      font-size: 13px;
      line-height: 20px;
      padding: 3px;
      font-weight: 500;
      border-radius: 4px;
      margin-right: 5px;
      background-color: #fbfbff;
      min-width: 30px;
      transition: 0.2s;
      svg {
        max-width: 15px;
      }
      &.active {
        background-color: #5d78ff;
        color: white;
      }
      &.arrow {
        background-color: #f0f3ff;
        path {
          fill: #93a2dd;
        }
        &[disabled] {
          pointer-events: none;
          background-color: #fbfbff;
          path {
            fill: #dfe3f5;
          }
        }
      }
    }
  }