.answer {
  &-wrapper {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }
  &-correct {
    flex-basis: 50px;
    max-width: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-bottom: 20px;
    input {
      display: none;
      &:checked + label {
        &::before {
          border-color: #3699ff;
        }
        &::after {
          opacity: 1;
        }
      }
    }
    label {
      cursor: pointer;
      position: relative;
      &::before {
        content: "";
        display: inline-block;
        width: 16px;
        height: 16px;
        border: 1px solid #e2e5ec;
        border-radius: 8px;
        background-color: #fff;
        transition: 0.2s;
      }
      &::after {
        content: "";
        position: absolute;
        left: 4px;
        top: 4px;
        display: inline-block;
        width: 8px;
        height: 8px;
        border-radius: 8px;
        background-color: #3699ff;
        transition: 0.2s;
        opacity: 0;
      }
    }
  }
  &-content {
    flex: 1;
  }
}
