.table {
  width: 100%;
  background-color: transparent;
  &__header {
    tr {
      border-bottom: 1px solid $separator;
    }
    th {
      padding: 18px 0px;
      &:first-child {
        padding-left: 28px;
      }
    }
  }
  &__body {
    tr {
      cursor: pointer;
      border-bottom: 1px solid $separator;
      &:hover {
        background-color: rgb(249, 249, 249);
      }
    }
    td {
      font-family: "Poppins";
      padding: 20px 0px;
      vertical-align: middle;
      font-size: 13px;
      line-height: 20px;
      color: #595d6e;
    }
    tr {
      td:first-child {
        padding-left: 28px;
        &.child {
          position: relative;

          &--1 {
            padding-left: 63px;
          }
          &--2 {
            padding-left: 98px;
          }
        }
      }
      .category-span {
        position: relative;
        &::before {
          content: "";
          position: absolute;
          top: 0;
          bottom: 0;
          left: -44px;
          width: 23px;
          height: 2px;
          background-color: #597dff;
          opacity: 0.4;
          top: 49%;
        }
        &--hide {
          &::before {
            display: none;
          }
        }
      }
    }
  }
  &__double-label {
    display: inline-block;
    margin: 5px;
    white-space: nowrap;
    font-family: "Poppins", sans-serif;
  }
  &__label {
    background-color: rgb(236, 236, 236);
    padding: 2px 8px;
    margin-right: 8px;
    font-size: 11px;
    line-height: 16px;
    border-radius: 4px;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    &--green {
      color: rgb(10, 187, 187);
      background-color: rgba(10, 187, 187, 0.2);
    }
    &--red {
      color: rgb(253, 57, 57);
      background-color: rgba(253, 57, 57, 0.2);
    }
    &--yellow {
      color: rgb(255, 184, 34);
      background-color: rgba(255, 184, 34, 0.2);
    }
    &--violet {
      color: rgb(103, 68, 241);
      background-color: rgba(103, 68, 241, 0.2);
    }
  }
  &__label-left {
    background-color: rgb(236, 236, 236);
    padding: 3px 8px;
    font-size: 11px;
    //border-radius: 4px;
    border-radius: 4px 0 0 4px;
    font-weight: 500;
    &--green {
      color: rgb(10, 187, 187);
      background-color: rgba(10, 187, 187, 0.2);
    }
    &--red {
      color: rgb(253, 57, 57);
      background-color: rgba(253, 57, 57, 0.2);
    }
    &--yellow {
      color: rgb(255, 184, 34);
      background-color: rgba(255, 184, 34, 0.2);
    }
    &--violet {
      color: rgb(103, 68, 241);
      background-color: rgba(103, 68, 241, 0.2);
    }
  }
  &__label-right {
    padding: 2px 8px;
    font-size: 11px;
    border: 1px solid rgb(236, 236, 236);
    border-radius: 0 4px 4px 0;
    font-weight: 500;
    //margin-right: 8px;
    &--orange {
      color: #ff5a00;
      border-color: #ff5a00;
    }
    &--blue {
      color: #0064d2;
      border-color: #0064d2;
    }
    &--green {
      color: #99cc00;
      border-color: #99cc00;
    }
  }
  &__platform {
    margin-right: 10px;
    line-height: 16px;
    padding: 2px 8px;
    font-size: 11px;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    border: 1px solid rgb(236, 236, 236);
    border-radius: 4px;
    &--orange {
      color: #ff5a00;
      border-color: #ff5a00;
    }
    &--blue {
      color: #0064d2;
      border-color: #0064d2;
    }
    &--green {
      color: #99cc00;
      border-color: #99cc00;
    }
  }
  &__icon {
    margin-right: 10px;
    //padding-right: 10px;
    cursor: pointer;
    path {
      fill: #91a0da;
    }
    &--purple {
      svg {
        transform: translateY(-2px) scale(1.2);
      }
      path {
        fill: $pink;
      }
    }
  }
  &__inputs {
    td {
      padding-right: 30px;
    }
  }
  &__photo {
    max-width: 40px;
    max-height: 40px;
    display: block;
    margin-top: auto;
    margin-bottom: auto;
  }
}