.popup-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  padding: 20px 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
  display: flex;
  overflow-y: scroll;
}
.popup {
  max-width: 424px;
  margin: auto;
  width: 100%;
  background-color: #fff;
  border-radius: 5px;
  opacity: 0;
  transition: 0.2s;
  &.active {
    opacity: 1;
  }
  &__header {
    padding: 18px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #ebedf2;
    button:only-child {
      margin-left: auto;
    }
    h2{
      font-size: 16px;
      line-height: 20%;
      font-weight: 500;
    }
  }
  &__body {
    padding: 20px;
  }
  &__footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    button {
      margin-left: 11px;
    }
    padding: 10px 15px 10px 15px;
    border-top: 1px solid #ebedf2;
    img {
      vertical-align: middle;
    }
  }
  &.popup__login {
    .popup__body {
      padding: 20px;
      .login_button {
        text-align: center;
      }
    }
  }
}
